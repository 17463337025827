export const kycStatusConst = {
  UNKNOWN: 'UNKNOWN',
  NOT_AVAILABLE: 'NOT_AVAILABLE', // Reupload
  VERIFICATION_PENDING: 'VERIFICATION_PENDING', // No need to reupload, wait to change status
  VERIFICATION_FAILED: 'VERIFICATION_FAILED', // No need to reupload, wait to change status
  VERIFIED: 'VERIFIED', // Verified doc or ekyc avail
  SKIP_VERIFICATION: 'SKIP_VERIFICATION',
  HIDE: 'HIDE', // don't show this item to user
  OPTIONAL: 'OPTIONAL', // show it but allow user to continue without it
  DISABLE: 'DISABLE', // show but disable the option, this keep the UI consistent
  SHOW: 'SHOW'
};

export const kycHelpConst = {
  KYC: 'Help KYC',
  DASHBOARD: 'KYC_DASHBOARD',
  SELFIE: 'Selfie',
  ADDRESS: 'Address',
  PAN: 'PAN'
};

export const ewiHelpConst = {
  INSTANT_LOAN: 'Help EI loan Bank transfer',
  INSTANT_LOAN_CONFIRMATION: 'Help EI loan disbursement',
  PLAN_DETAILS: 'Help EI plan details',
  INSTALMENT_SCHEDULE: 'Help Instalment Schedule',
  LOAN_OFFERS: 'Help with loan offer'
};

export const signUpHelpConst = {
  SIGNUP: 'Help UserProfile'
};

export const ckycHelpConst = {
  CKYC: 'Help CKYC',
  DASHBOARD: 'CKYC_DASHBOARD'
};

export const repayHelpConst = {
  REPAY: 'Help Repayment'
};

export const dashboardHelpConst = 'Help DashBoard';
export const helpAccountChangeConst = 'Help Account Change';

export const kycTypeConst = {
  ADDRESS_DIGILOCKER: 'ADDRESS_DIGILOCKER',
  ADDRESS_OCR: 'ADDRESS_OCR',
  ADDRESS_OCR_AADHAAR: 'ADDRESS_OCR_AADHAAR',
  ADDRESS_OCR_EPIC: 'ADDRESS_OCR_EPIC',
  ADDRESS_OCR_DL: 'ADDRESS_OCR_DL',
  PAN: 'PAN'
};

export const docTypeOpt = {
  ADDRESS_OCR_AADHAAR: {
    enumVal: 4,
    name: 'Aadhar',
    gaEvent: 'normal_address_aadhaar_upload'
  },
  ADDRESS_OCR_DL: {
    enumVal: 0,
    name: 'DL',
    gaEvent: 'normal_address_dl_upload'
  },
  ADDRESS_OCR_EPIC: {
    enumVal: 5,
    name: 'EPIC',
    gaEvent: 'normal_address_voterid_upload'
  },
  PAN: {
    enumVal: 3,
    name: 'PAN',
    gaEvent: 'normal_address_voterid_upload'
  }
};

export const MISSING_KYC = {
  BOTH: 'BOTH',
  NAME: 'NAME',
  SELFIE: 'SELFIE'
};

export const USER_ACTION = {
  NO_USER_ACTION: 'NO_USER_ACTION',
  PROFILE_UPDATE_REQUIRED: 'PROFILE_UPDATE_REQUIRED',
  KYC_ACTION_REQUIRED: 'KYC_ACTION_REQUIRED',
  BANK_SETUP_REQUIRED: 'BANK_SETUP_REQUIRED',
  CONSENT_REQUIRED: 'CONSENT_REQUIRED',
  NBFC_FINISHING_UP: 'NBFC_FINISHING_UP',
  ONBOARD_REQUIRED: 'ONBOARD_REQUIRED'
};

export const LOCK_ACTION = {
  NO_ACTION: 'NO_ACTION',
  TRY_AGAIN: 'TRY_AGAIN',
  CONTACT_SUPPORT: 'CONTACT_SUPPORT',
  ONBOARD_DATA_VERIFICATION: 'ONBOARD_DATA_VERIFICATION'
};

export const tos_render_type = {
  HTML_TEXT: 'HTML_TEXT',
  INTERNAL_URL: 'INTERNAL_URL',
  EXTERNAL_URL: 'EXTERNAL_URL',
  NESL_URL: 'NESL_URL'
};

export const kycItemsConst = {
  PAN: 'PAN',
  ADDRESS: 'ADDRESS',
  SELFIE: 'SELFIE'
}