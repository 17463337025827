import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
  getAccountAndPreferencesApi,
  getOnboardDashboardApi,
  getAllTransactionsApi,
  getTransactionDetailsApi,
  getSettingsUserNbfcTermsApi,
  getTermsAndConditionApi,
  acceptTermsAndConditionApi,
  getUsageAndRepaymentApi,
  checkUserEligibilityApi,
  getLOCDashboardApi
} from './dashboardApi';
import { getUserProfileInfoApi, listBankAccountApi, addBankAccountApi, chooseLoanAmountApi } from './userApi';

// Mock Responses
// import accAndPrefRes from './mockResponse/getAccAndPref.json';
// import checkEligbilityRes from './mockResponse/checkEligibility.json';
// import chooseLoanAmountRes from './mockResponse/chooseLoanAmount.json';
// import onboardDashboardRes from './mockResponse/onboardDashboard.json';
// import userProfileRes from './mockResponse/userProfileInfo.json';

const initialState = {
  // businessSelected: {},
  accountAndPreferences: [],
  onboardDashboardDetails: {},
  isDashboardPageLoading: false,
  isCheckingUserEligibility: false,
  userProfile: {},
  bankAccountList: [],
  pennyDropDetails: {},
  transactionList: [],
  isTransactionListFetching: false,
  transactionDetail: {},
  nbfcTerms: {},
  termsAndConditions: {},
  termsAndConditionsToAccept: {},
  isGettingTermsAndCondition: false,
  isFetchingUsageSummary: false,
  usageAndRepaymentSummary: {},
  usageAndRepaymentErrorMsg: '',
  eligibilityResponse: {},
  chooseLoanAmountDetails: {},
  locDashboardDetails: {}
};

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setPennyDropFlow: (state, action) => {
      state.isPennyDropFlow = action.payload;
    },
    resetBankAccount: (state, action) => {
      let updFields = action.payload;
      updFields = {
        ...updFields,
        availBankAcc: {}
      };
      state.bankAccountList = updFields;
    }
  },
  extraReducers: {
    [getOnboardDashboardApi.pending]: (state) => {
      state.isDashboardPageLoading = true;
      state.onboardDashboardDetails = {};
    },
    [getOnboardDashboardApi.fulfilled]: (state, action) => {
      state.onboardDashboardDetails = action.payload;
      // state.onboardDashboardDetails = onboardDashboardRes.successScenario;
      state.isDashboardPageLoading = false;
    },
    [getOnboardDashboardApi.rejected]: (state) => {
      state.isDashboardPageLoading = false;
      state.onboardDashboardDetails = {};
    },
    [getAccountAndPreferencesApi.pending]: (state) => {
      state.isDashboardPageLoading = true;
    },
    [getAccountAndPreferencesApi.fulfilled]: (state, action) => {
      // state.accountAndPreferences = accAndPrefRes.scenarioOne;
      state.accountAndPreferences = action.payload;
      state.isDashboardPageLoading = false;
    },
    [getAccountAndPreferencesApi.rejected]: (state) => {
      state.isDashboardPageLoading = false;
    },
    [getLOCDashboardApi.pending]: (state) => {
      state.isDashboardPageLoading = true;
    },
    [getLOCDashboardApi.fulfilled]: (state, action) => {
      // state.accountAndPreferences = accAndPrefRes.scenarioOne;
      state.locDashboardDetails = action.payload;
      state.isDashboardPageLoading = false;
    },
    [getLOCDashboardApi.rejected]: (state) => {
      state.isDashboardPageLoading = false;
    },
    [getUserProfileInfoApi.pending]: (state) => {
      state.userProfile = {};
      // state.userProfile = userProfileRes.userProfileNotComplete;
    },
    [getUserProfileInfoApi.fulfilled]: (state, action) => {
      state.userProfile = action.payload;
      // state.userProfile = userProfileRes.userProfileNotComplete;
    },
    [listBankAccountApi.fulfilled]: (state, action) => {
      state.bankAccountList = action.payload;
    },
    // [delelteBankAccountApi.fulfilled]: (state, action) => {
    //   state.bankAccountList = {};
    // },
    [chooseLoanAmountApi.pending]: (state) => {
      state.chooseLoanAmountDetails = {};
    },
    [chooseLoanAmountApi.fulfilled]: (state, action) => {
      state.chooseLoanAmountDetails = action.payload;
      // state.chooseLoanAmountDetails = chooseLoanAmountRes.nbfcAccNotReadySchenario;
    },
    [getAllTransactionsApi.pending]: (state, action) => {
      state.isTransactionListFetching = true;
    },
    [getAllTransactionsApi.fulfilled]: (state, action) => {
      state.transactionList = action.payload;
      state.isTransactionListFetching = false;
    },
    [getAllTransactionsApi.rejected]: (state, action) => {
      state.isTransactionListFetching = false;
    },
    [getTransactionDetailsApi.fulfilled]: (state, action) => {
      state.transactionDetail = action.payload;
    },
    [addBankAccountApi.fulfilled]: (state, action) => {
      const res = action.payload;
      if (res.penny_drop_amount) {
        state.pennyDropDetails = res;
      } else {
        state.pennyDropDetails = null;
      }
    },
    [getSettingsUserNbfcTermsApi.fulfilled]: (state, action) => {
      state.nbfcTerms = action.payload;
    },
    [acceptTermsAndConditionApi.fulfilled]: (state, action) => {
      state.onboardDashboardDetails = {};
    },
    [getTermsAndConditionApi.pending]: (state) => {
      state.isGettingTermsAndCondition = true;
    },
    [getTermsAndConditionApi.fulfilled]: (state, action) => {
      state.termsAndConditions = action.payload;
      state.isGettingTermsAndCondition = false;
      if (
        _.isArray(action.payload?.terms_and_conditions_to_accept) &&
        !_.isEmpty(action.payload?.terms_and_conditions_to_accept)
      ) {
        state.termsAndConditionsToAccept = action.payload?.terms_and_conditions_to_accept[0];
      }
    },
    [getTermsAndConditionApi.rejected]: (state) => {
      state.isGettingTermsAndCondition = false;
    },
    [getUsageAndRepaymentApi.pending]: (state) => {
      state.isFetchingUsageSummary = true;
      state.usageAndRepaymentSummary = {};
      state.usageAndRepaymentErrorMsg = '';
    },
    [getUsageAndRepaymentApi.fulfilled]: (state, action) => {
      state.usageAndRepaymentSummary = action.payload;
      // state.usageAndRepaymentSummary = usageAndRepaymentMockData;
      state.isFetchingUsageSummary = false;
      state.usageAndRepaymentErrorMsg = '';
    },
    [getUsageAndRepaymentApi.rejected]: (state, action) => {
      state.isFetchingUsageSummary = false;
      state.usageAndRepaymentSummary = {};
      state.usageAndRepaymentErrorMsg = action.payload?.message;
    },
    [checkUserEligibilityApi.pending]: (state) => {
      state.isCheckingUserEligibility = true;
    },
    [checkUserEligibilityApi.fulfilled]: (state, action) => {
      state.isCheckingUserEligibility = false;
      state.eligibilityResponse = action.payload;
      // state.eligibilityResponse = checkEligbilityRes.scenario1;
    },
    [checkUserEligibilityApi.rejected]: (state) => {
      state.isCheckingUserEligibility = false;
    }
  }
});

export const dashboardActions = slice.actions;

export default slice.reducer;
