import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Box, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PaperBox from 'components/PaperBox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import CustomSubmitButton from 'components/CustomSubmitButton';
import Page from 'components/Page';
import useLocales from 'hooks/useLocales';
import { PATH_AUTH } from 'routes/paths';
import { localStorageKey } from 'utils/constants/common';
import { appendSearchParams } from 'utils/common';

const RootStyle = styled(Page)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  maxHeight: '100%',
  padding: '48px 24px 32px 24px'
}));

function InitLanguages(props) {
  const { isLoginScreen, closeLanguageDialog } = props;
  const { onChangeLang, allLang, translate, currentLang } = useLocales();
  const navigate = useNavigate();
  const isLangSelected = sessionStorage.getItem(localStorageKey.isLangSelected);

  useEffect(() => {
    if (isLangSelected === 'true') {
      navigate(appendSearchParams(PATH_AUTH.login), { replace: true });
    }
  }, [isLangSelected]);

  const onBtSubmit = () => {
    if (isLoginScreen) {
      sessionStorage.setItem(localStorageKey.isLangSelected, 'true');
      closeLanguageDialog();
    } else {
      navigate(appendSearchParams(PATH_AUTH.login), { replace: true });
    }
  };

  return (
    <RootStyle title="Karmalife | Support">
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <PaperBox
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '24px',
            textAlign: 'center',
            overflow: 'scroll'
            // position: 'sticky'
          }}
        >
          <Box component="img" src="/static/icon/Language.svg" />
          <Typography sx={{ fontSize: '16px', color: '#000000', fontWeight: 500, lineHeight: '22px', mt: '16px' }}>
            {translate('language_tv_choose_language_title')}
          </Typography>
          <Typography sx={{ fontSize: '14px', color: '#000000', fontWeight: 400, lineHeight: '22px' }}>
            {translate('language_tv_choose_language_description')}
          </Typography>
          <FormControl sx={{ marginTop: 6 }}>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              onChange={({ target: { value } }) => onChangeLang(value)}
              name="prefLanguage"
              value={currentLang?.value}
            >
              {allLang.map((lang) => (
                <FormControlLabel
                  key={lang?.value}
                  value={lang?.value}
                  control={<Radio disabled={lang?.disabled} />}
                  label={lang?.label}
                />
              ))}
              {/* <FormControlLabel value="one" control={<Radio />} label="English" />
              <FormControlLabel value="two" control={<Radio />} label="हिन्दी" />
              <FormControlLabel value="three" control={<Radio />} label="ಕನ್ನಡ" />
              <FormControlLabel value="four" control={<Radio />} label="தமிழ்" />
              <FormControlLabel value="five" control={<Radio />} label="తెలుగు" /> */}
            </RadioGroup>
          </FormControl>
        </PaperBox>
        <Box
          sx={{
            marginTop: 3,
            paddingBottom: '8px',
            position: 'sticky',
            bottom: 10,
            width: '100%',
            backgroundColor: 'white'
          }}
        >
          <CustomSubmitButton label={translate('login_fail_button_continue_signup_label')} onButtonPress={onBtSubmit} />
        </Box>
      </Box>
    </RootStyle>
  );
}

export default InitLanguages;
