import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useLocales from 'hooks/useLocales';
import { localStorageKey } from 'utils/constants/common';

// material
import { repayHelpConst } from 'utils/constants/kycConst';
import { styled } from '@mui/material/styles';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { AppBar, Toolbar, Typography, IconButton, Stack, Menu, MenuItem } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { ROOT_AMOUNT_REPAYMENT } from 'routes/paths';
import { whatsApp } from '../../config';
// components
//

// import NotificationsPopover from './NotificationsPopover';
// import AccountPopover from './AccountPopover';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined
  });

  return React.cloneElement(children, {
    elevation: trigger ? 2 : 0,
    sx: { backgroundColor: trigger ? 'white' : 'transparent', transition: 'all 0.2s ease' }
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
};

const RootStyle = styled(AppBar)(({ theme }) => ({
  // backgroundColor: 'transparent',
  // boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
  height: 64
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  justifyContent: 'space-between',
  padding: '0px 24px'
}));

// ----------------------------------------------------------------------

RepayNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function RepayNavbar(props) {
  const navigate = useNavigate();
  const { translate } = useLocales();
  const [anchorEl, setAnchorEl] = useState(null);
  const pathName = window.location.pathname?.split('/').pop();
  const open = Boolean(anchorEl);

  const onMenuBt = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // const getHeader = () => {
  //   let header = 'Complete your KYC';
  //   switch (pathName) {
  //     case 'address-verify':
  //       header = 'Aadhaar Verification';
  //       break;
  //     case 'bank-register':
  //       header = 'Bank Transfer';
  //       break;
  //     default:
  //       break;
  //   }
  //   return header;
  // };

  const onBtSupport = () => {
    const phone = sessionStorage.getItem(localStorageKey.phone);
    let whatsAppUrl = `${whatsApp.url}/${whatsApp.number}`;
    let text = repayHelpConst.REPAY;
    handleMenuClose();
    switch (pathName) {
      case ROOT_AMOUNT_REPAYMENT:
        text = text.concat(' ', repayHelpConst.REPAY);
        break;
      default:
        break;
    }
    whatsAppUrl = whatsAppUrl.concat(`?text=${encodeURI(`${text}\n${phone}`)}`);
    window.open(whatsAppUrl, '_blank').focus();
  };

  const showHelpIcon = () => {
    switch (pathName) {
      case 'repayment':
        return true;
      default:
        return false;
    }
  };

  const handleBackClick = () => {
    switch (pathName) {
      case 'repayment':
        navigate(-1);
        break;
      default:
        return window.history.back();
    }
  };
  const { pathname } = useLocation();

  function getNavLabel() {
    console.log(pathname);
    switch (pathname) {
      case '/ewi/due-payment':
        return 'Due repayment';
      case '/ewi/full-payment':
        return 'Full repayment';
      default:
        return 'Instalment payment';
    }
  }

  return (
    <>
      <Menu
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        anchorEl={anchorEl}
        open={open}
        dense
        onClose={handleMenuClose}
        // anchorOrigin={{
        //   vertical: 'top',
        //   horizontal: 'left'
        // }}
        // transformOrigin={{
        //   vertical: 'top',
        //   horizontal: 'left'
        // }}
      >
        <MenuItem onClick={onBtSupport}>{translate('common_contact_support_label')} </MenuItem>
      </Menu>
      <ElevationScroll {...props}>
        <RootStyle position="fixed">
          <ToolbarStyle>
            <Stack direction="row" justifyContent="flex-start" alignItems="center">
              <IconButton edge="start" aria-label="back arrow" sx={{ mr: 1, color: 'black' }} onClick={handleBackClick}>
                <ArrowBackIcon />
              </IconButton>
              <Typography sx={{ color: 'black', fontWeight: 500, fontSize: 20 }} component="div">
                {getNavLabel()}
              </Typography>
            </Stack>
            {showHelpIcon() && (
              <IconButton edge="start" aria-label="help" sx={{ color: 'black' }} onClick={onMenuBt}>
                <HelpOutlineIcon />
              </IconButton>
            )}
          </ToolbarStyle>
        </RootStyle>
      </ElevationScroll>
    </>
  );
}
