import PropTypes from 'prop-types';
// material
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return (
    <Box
      component="img"
      // src={JSON.parse(sessionStorage.getItem('companyMeta'))?.companyLogo || '/favicon/karmaFavicon.png'}
      src="/favicon/karmaFavicon.webp"
      sx={{ height: 'auto', ...sx }}
    />
  );
}
