import axios from 'utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';

const urls = {
  getUserProfileInfo: 'common/user/GetUserProfileInfo',
  updateUserProfile: '/common/user/UpdateUserProfile',
  listBankAccount: '/common/user/ListBankAccounts',
  getIfscCode: '/common/misc/GetIFSCDetails',
  addBankAccount: '/common/user/AddBankAccount',
  locChooseAmountDetails: '/credit/transaction/LOCChooseLoanAmountDetail',
  deleteKYC: 'common/user/DeleteKyc',
  getResetItems: 'common/kyc/FetchOnboardVerificationResetItems'
};
// mock response
// import listBankAccountRes from "./mockResponse/listBankAccount.json"
// import { mockApiResponse } from 'utils/mockResponse';

export const getUserProfileInfoApi = createAsyncThunk('karma/getUserProfileInfo', async (params, thunkApi) => {
  try {
    const response = await axios.post(urls.getUserProfileInfo, params);
    if (response.data) {
      const resData = { ...response.data };
      resData.isActiveLoanAccount = !!_.find(resData?.loanAccounts, (acc) => acc?.is_active);
      return resData;
    }
    return thunkApi.rejectWithValue('Error! Try again later');
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const updateUserProfileApi = createAsyncThunk('karma/updateUserProfile', async (params, thunkApi) => {
  try {
    const response = await axios.post(urls.updateUserProfile, params);
    if (response.data) {
      return response.data;
    }
    return thunkApi.rejectWithValue('Error! Try again later');
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});
export const listBankAccountApi = createAsyncThunk('karma/listBankAccountApi', async (params, thunkApi) => {
  try {
    // return mockApiResponse(listBankAccountRes.successScenario, 100);
    const response = await axios.post(urls.listBankAccount, params);
    if (response.data) {
      const resData = { ...response.data };

      if (!_.isEmpty(resData?.items) && resData?.items.length >= 1) {
        resData.availBankAcc = resData?.items[0];
      }
      return resData;
    }
    return thunkApi.rejectWithValue('Error! Try again later');
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});
export const getIfscCodeApi = createAsyncThunk('karma/getIfscCodeApi', async (params, thunkApi) => {
  try {
    const response = await axios.get(`${urls.getIfscCode}/${params.ifscCode}`);
    if (response?.data) {
      return response.data;
    }
    return thunkApi.rejectWithValue('Error! Try again later');
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const addBankAccountApi = createAsyncThunk('karma/addBankAccount', async (params, thunkApi) => {
  try {
    const response = await axios.post(urls.addBankAccount, params);
    if (response.data && response.data.id) {
      return response.data;
    }
    return thunkApi.rejectWithValue('Error! Try again later');
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const chooseLoanAmountApi = createAsyncThunk('karma/credit/chooseLoanAmountApi', async (params, thunkApi) => {
  try {
    const response = await axios.post(urls.locChooseAmountDetails,params);
    if (response.data) {
      return response.data;
    }
    return thunkApi.rejectWithValue('Error! Try again later');
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const deleteKYCApi = createAsyncThunk('karma/deleteKYCApi', async (params, thunkApi) => {
  try {
    const response = await axios.put(urls.deleteKYC, params);
    if (response.data) {
      return response.data;
    }
    return thunkApi.rejectWithValue('Error! Try again later');
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const getResetKycItems = createAsyncThunk('karma/getResetKycItems', async (params, thunkApi) => {
  try {
    const response = await axios.post(urls.getResetItems, params);
    if (response.data) {
      return response.data;
    }
    return thunkApi.rejectWithValue('Error! Try again later');
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});
