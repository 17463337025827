// import jwtDecode from 'jwt-decode';
// import { verify, sign } from 'jsonwebtoken';
// //
import _ from 'lodash';
import { localStorageKey } from 'utils/constants/common';
import { v1 as uuidv1 } from 'uuid';
import axios from './axios';
import { getUserLocation } from './locationHelper';

// // ----------------------------------------------------------------------

// const isValidToken = (accessToken) => {
//   if (!accessToken) {
//     return false;
//   }

//   const decoded = jwtDecode(accessToken);
//   const currentTime = Date.now() / 1000;

//   return decoded.exp > currentTime;
// };

// const getDecodedToken = (accessToken) => {
//   if (!accessToken) {
//     return false;
//   }
//   return jwtDecode(accessToken);
// };

// const setLoginToken = (token) => {
//   if (token) {
//     sessionStorage.setItem('x-login-token', token);
//     axios.defaults.headers['x-login-token'] = token;
//   } else {
//     sessionStorage.removeItem('x-login-token');
//     delete axios.defaults.headers['x-login-token'];
//   }
// };

const setSession = (accessToken) => {
  if (accessToken) {
    sessionStorage.setItem(localStorageKey.accessToken, accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // axios.defaults.headers.version = 199;
    axios.defaults.headers['X-Version-Code'] = window?.env?.VERSION_CODE || 514;
    const langSelected = sessionStorage.getItem(localStorageKey.i18nextLng);
    if (langSelected) {
      axios.defaults.headers['X-Language'] = langSelected;
    }

    // This function below will handle when token is expired
    // const { exp } = jwtDecode(accessToken);
    // handleTokenExpired(exp);
  }
};

const createTransferParams = async (reqParams) => {
  let usLocString = sessionStorage.getItem('userLocation');

  if (_.isEmpty(usLocString)) {
    await getUserLocation();
    usLocString = sessionStorage.getItem('userLocation');
  }

  if (_.isEmpty(usLocString)) {
    await getUserLocation();
    usLocString = sessionStorage.getItem('userLocation');
  }
  const userLocation = !_.isEmpty(usLocString) ? JSON.parse(usLocString) : {};

  let device_id = sessionStorage.getItem(localStorageKey.deviceId);
  if (_.isEmpty(device_id)) {
    device_id = uuidv1();
    sessionStorage.setItem(localStorageKey.deviceId, device_id);
  }
  const updatedReq = {
    ...reqParams,
    device_id,
    location: {
      latitude: userLocation?.latitude || 0,
      longitude: userLocation?.longitude || 0,
      location: userLocation?.display_name || 'Location xyz ',
      state: userLocation?.address?.state || 'Karnataka',
      country: userLocation?.address?.country_code?.toUpperCase() || 'IN',
      postalCode: userLocation?.address?.postcode || '112233'
    }
  };
  return updatedReq;
};

const getUserId = () => sessionStorage.getItem(localStorageKey.user_id);

export { setSession, getUserId, createTransferParams };
