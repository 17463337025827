import { useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
// hooks
import { PATH_AUTH } from 'routes/paths';
import useAuth from '../hooks/useAuth';
import { localStorageKey } from '../utils/constants/common';
// pages
import Login from '../pages/authentication/Login';

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default function AuthGuard({ children }) {
  const { isAuthenticated } = useAuth();
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    console.log(pathname);
    // will handle only repayment link redirections
    if (pathname === '/repayment/' || pathname === '/repayment') {
      sessionStorage.setItem(localStorageKey.deeplinkParam, pathname);
      const urlSearchParams = new URLSearchParams(search);
      urlSearchParams.set('deeplink', pathname);
      // const sep = search ? '&' : '?';
      return <Navigate to={`${PATH_AUTH.login}?${urlSearchParams.toString()}`} replace />;
    }
    return <Navigate to={`${PATH_AUTH.login}${search}`} replace />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={`${requestedLocation}${search}`} />;
  }

  return <>{children}</>;
}
