import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useLocales from 'hooks/useLocales';
import { localStorageKey } from 'utils/constants/common';
import LogoutIcon from '@mui/icons-material/Logout';
// material
import { styled } from '@mui/material/styles';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Stack,
  Menu,
  MenuItem,
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Box
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { PATH_KYC, PATH_LOC_DASHBOARD, PATH_BANK, ONBOARD_SETUP, LOADING } from 'routes/paths';
import { kycHelpConst } from 'utils/constants/kycConst';
import useAuth from 'hooks/useAuth';
import { kycActions } from 'redux/kyc';
import { useDispatch } from 'react-redux';
import { whatsApp } from '../../config';

// components
//

// import NotificationsPopover from './NotificationsPopover';
// import AccountPopover from './AccountPopover';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined
  });

  return React.cloneElement(children, {
    elevation: trigger ? 2 : 0,
    sx: { backgroundColor: trigger ? 'white' : 'transparent', transition: 'all 0.2s ease' }
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
};

const RootStyle = styled(AppBar)(({ theme }) => ({
  // backgroundColor: 'transparent',
  // boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
  height: 64
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  justifyContent: 'space-between',
  padding: '0px 24px'
}));

// ----------------------------------------------------------------------

KycNavbarV2.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function KycNavbarV2(props) {
  const { translate } = useLocales();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { pathname } = useLocation();
  const { logout } = useAuth();
  const dispatch = useDispatch();

  const [openLogoutDialog, setLogoutDialog] = React.useState(false);

  // const pathName = window.location.pathname?.split('/').pop();

  // To Track back button
  const [finishStatus, setfinishStatus] = useState(false);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      const params = {
        messege: 'kyc_dashboard_popup_pan_help_title',
        showErrorCard: true,
        backFrom: 'panDoc'
      };
      dispatch(kycActions.setBackNavErrDetails(params));
      window.history.back();
    }
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);

  const onMenuBt = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onBtLogout = () => {
    setLogoutDialog(true);
  };

  const closeLogoutDialog = () => {
    setLogoutDialog(false);
  };

  const onBtSupport = () => {
    const phone = sessionStorage.getItem(localStorageKey.phone);
    let whatsAppUrl = `${whatsApp.url}/${whatsApp.number}`;
    let text = kycHelpConst.KYC;
    handleMenuClose();
    switch (pathname) {
      case PATH_KYC.root:
        text = text.concat(' ', kycHelpConst.DASHBOARD);
        break;
      case PATH_KYC.selfie:
        text = text.concat(' ', kycHelpConst.SELFIE);
        break;
      case PATH_KYC.pancard:
        text = text.concat(' ', kycHelpConst.PAN);
        break;
      case PATH_KYC.addressDetail:
        text = text.concat(' ', kycHelpConst.ADDRESS);
        break;
      case PATH_KYC.addressDashboard:
        text = text.concat(' ', kycHelpConst.ADDRESS);
        break;

      default:
        break;
    }
    whatsAppUrl = whatsAppUrl.concat(`?text=${encodeURI(`${text}\n${phone}`)}`);
    window.open(whatsAppUrl, '_blank').focus();
  };

  const getHeader = () => {
    let header = translate('dashboard_st_block_subhead_label');
    switch (pathname) {
      case PATH_KYC.address_verify:
        header = translate('aadhaar_verify_textView53_label');
        break;
      case PATH_KYC.root:
        header = 'Upload your documents';
        break;
      case PATH_BANK.register:
        header = translate('common_bank_transfer_label');
        break;
      case 'myplans':
        header = translate('settings_textViewF1_label');
        break;
      case PATH_KYC.usage_repayment:
        header = translate('settings_tv_title');
        break;
      case PATH_KYC.user_tutorial:
        header = 'Welcome Sumit';
        break;
      default:
        break;
    }
    return header;
  };

  const showHelpIcon = () => {
    switch (pathname) {
      case PATH_KYC.root:
        return true;
      case PATH_KYC.address_verify:
        return true;
      case PATH_KYC.addressDetail:
        return true;
      case PATH_KYC.address_photo:
        return true;
      case PATH_KYC.address_proof_doc:
        return true;
      case PATH_KYC.selfie:
        return true;
      case PATH_KYC.pancard:
        return true;
      case PATH_KYC.addressDashboard:
        return true;
      case PATH_BANK.register:
        return false;
      case PATH_KYC.pancard_details:
        return true;
      default:
        return false;
    }
  };

  function setErrorDetails(path) {
    const params = {
      messege: 'kyc_dashboard_popup_pan_help_title',
      showErrorCard: true,
      backFrom: 'panDoc'
    };
    dispatch(kycActions.setBackNavErrDetails(params));
  }

  const handleBackClick = () => {
    switch (pathname) {
      case PATH_KYC.root:
        navigate(LOADING, { replace: true });
        break;

      case PATH_KYC.selfie:
        navigate(PATH_KYC.root, { replace: true });
        break;
      case PATH_KYC.address:
        navigate(PATH_KYC.root, { replace: true });
        break;

      case PATH_KYC.address_verify:
        navigate(PATH_KYC.address, { replace: true });
        break;
      case PATH_BANK.register:
        navigate(PATH_LOC_DASHBOARD.root, { replace: true });
        break;

      case PATH_KYC.pancard:
        setErrorDetails(pathname);
        navigate(PATH_KYC.root, { replace: true });
        break;

      case PATH_KYC.addressDetail:
        navigate(PATH_KYC.root, { replace: true });
        break;
      case PATH_KYC.addressDashboard:
        navigate(PATH_KYC.root, { replace: true });
        break;
      case PATH_KYC.address_photo:
        navigate(PATH_KYC.addressDashboard, { replace: true });
        break;
      case PATH_KYC.address_proof_doc:
        sessionStorage.removeItem(localStorageKey.address_doc);
        navigate(PATH_KYC.address_photo, { replace: true });
        break;
      default:
        navigate(LOADING, { replace: true });
        break;
    }
  };

  return (
    <>
      <Menu
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        anchorEl={anchorEl}
        open={open}
        dense
        onClose={handleMenuClose}
        // anchorOrigin={{
        //   vertical: 'top',
        //   horizontal: 'left'
        // }}
        // transformOrigin={{
        //   vertical: 'top',
        //   horizontal: 'left'
        // }}
      >
        <MenuItem onClick={onBtSupport}>{translate('common_contact_support_label')} </MenuItem>
        <MenuItem onClick={onBtLogout}>{translate('common_logout')} </MenuItem>
      </Menu>
      <Dialog
        open={openLogoutDialog}
        onClose={closeLogoutDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{translate('logout_dialog_title')} </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={logout} sx={{ color: 'red' }}>
            {translate('logout_dialog_yes')}
          </Button>
          <Button onClick={closeLogoutDialog}>{translate('logout_dialog_no')}</Button>
        </DialogActions>
      </Dialog>
      <ElevationScroll {...props}>
        <RootStyle position="fixed">
          <ToolbarStyle>
            <Stack direction="row" justifyContent="flex-start" alignItems="center">
              <IconButton edge="start" aria-label="back arrow" sx={{ mr: 1, color: 'black' }} onClick={handleBackClick}>
                <ArrowBackIcon />
              </IconButton>

              <Typography sx={{ color: 'black', fontWeight: 500, fontSize: 20 }} component="div">
                {getHeader()}
              </Typography>
            </Stack>
            {showHelpIcon() && (
              <Box>
                <IconButton edge="start" aria-label="help" sx={{ color: 'black' }} onClick={onMenuBt}>
                  <HelpOutlineIcon />
                </IconButton>
                {/* <IconButton
                  edge="start"
                  aria-label="help"
                  sx={{ color: 'black', paddingLeft: '12px' }}
                  onClick={onMenuBt}
                >
                  <LogoutIcon sx={{ fontSize: '20px', color: 'red' }} />
                </IconButton> */}
              </Box>
            )}
          </ToolbarStyle>
        </RootStyle>
      </ElevationScroll>
    </>
  );
}
